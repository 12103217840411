import "vant/es/area/style";
import _Area from "vant/es/area";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/image/style";
import _Image from "vant/es/image";
import areaList from '@/utils/area';
export default {
  name: 'index',
  components: {
    [_Image.name]: _Image,
    [_Button.name]: _Button,
    [_Form.name]: _Form,
    [_Field.name]: _Field,
    [_Popup.name]: _Popup,
    [_Area.name]: _Area
  },
  data() {
    return {
      buttonLoad: false,
      showArea: false,
      areaList: areaList,
      // 数据格式见 Area 组件文档

      formData: {
        id: null,
        name: '',
        phone: '',
        city: '',
        address: ''
      }
    };
  },
  async created() {
    // let {id}=this.$route.params
    // if(id!=this.giftData.id){
    //     this.$toast({message:'该礼品不存在'})
    //     // this.$router.replace('/')
    // }
    this.formData.id = this.$route.params.id;
  },
  async mounted() {},
  methods: {
    phoneValidator(val) {
      console.log(val);
      return val.length == 11 && !isNaN(val) ? true : false;
    },
    onCityConfirm(values) {
      this.formData.city = values.map(item => item.name).join(' ');
      this.showArea = false;
    },
    async onSubmit() {
      console.log(this.formData);
      this.buttonLoad = true;
      let goodIds = this.$store.state.giftData.map(item => {
        return item.goodId;
      });
      let result = await this.$request({
        method: 'post',
        url: '/app/api/my/order/submit',
        data: {
          id: this.formData.id,
          goodIds: goodIds,
          name: this.formData.name,
          phone: this.formData.phone,
          address: this.formData.city + this.formData.address
        }
      });
      console.log(result);
      this.buttonLoad = false;
      if (result.code == 0) {
        this.$router.replace('/success');
      } else {
        this.$toast({
          message: result.message
        });
      }
    }
  }
};